<template>
  <div class="home">
    <img src="../../assets/images/privacyPolicyTitle.png" alt="" />
    <div class="bigContent">发布日期：2021年11月27日</div>
    <div class="bigContent">生效日期：2020年10月27日</div>
    <div class="bigTitle">提示条款</div>
    <div class="bigContent">
      “{{appName}}”的运营主体{{companyName}}（以下简称“我们”）深知个人信息对您的重要性，并会尽力保护您的个人信息安全可靠，我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则，目的明确原则，选择同意原则，最少够用原则，确保安全原则，主体参与原则，公开透明原则等等。同时我们承诺，我们将按业界成熟的安全解决方案，采取相应的安全保护措施来保护您的个人信息。鉴此，我们制定本《隐私政策》（下称“本政策
      /本隐私政策”）并提醒您：
    </div>
    <div class="bigContent">本政策适用于“{{appName}}”软件及相关服务。</div>
    <div class="bigContent">
      在使用“{{appName}}”软件及相关服务前，请您务必仔细阅读并理解本政策，在确认充分理解并同意后使用相关产品或服务。一旦您使用“{{appName}}”软件及相关服务，即表示您已充分理解并同意本政策。如对本政策内容有任何疑问、意见或建议，您可通过“{{appName}}”提供的各种联系方式与我们联系。
    </div>

    <div class="bigTitle">本隐私政策部分将帮助您了解以下内容：</div>
    <div class="bigContent">1、我们向您收集的个人信息及其用途</div>
    <div class="bigContent">2、我们可能需要获取的您的设备权限</div>
    <div class="bigContent">3、我们如何共享、披露您的个人信息</div>
    <div class="bigContent">4、我们如何保护您的个人信息</div>
    <div class="bigContent">5、您如何管理您的个人信息</div>
    <div class="bigContent">6、我们如何处理未成年人的个人信息</div>
    <div class="bigContent">7、我们存储您的个人信息的位置和方式</div>
    <div class="bigContent">8、本隐私政策如何更新</div>
    <div class="bigContent">9、如何联系我们</div>
    <div class="bigTitle">一、我们向您收集的个人信息及其用途</div>
    <div class="bigContent">
      个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息，我们将以下述目的，收集和使用您的个人信息：
    </div>
    <div class="bigContent">
      （一）
      我们会收集您的手机号码，用于为您创建“{{appName}}”的账号并为您提供相关服务。
    </div>
    <div class="bigContent">
      （二）
      我们会收集您在“{{appName}}”内的浏览及使用服务的记录，用于记录您的浏览、使用偏好，在“{{appName}}”内向您展示您可能感兴趣的内容。
    </div>
    <div class="bigContent">
      （三）
      我们会收集您选择上传的或自主自愿拍摄的头像照片，用于为您提供利用技术实现自我倾诉服务，如您不使用我们提供的上述服务，则我们不会收集您的头像照片。
    </div>
    <div class="bigContent">
      （四）
      我们会收集您的头像信息，用于验证您上传的照片是您本人，我们不会存储您的个人面部特征信息。
    </div>
    <div class="bigContent">
      （五）
      我们会根据您在安装及使用“{{appName}}”软件及相关服务的过程中授予的具体权限收集您所使用的设备相关信息（例如设备型号、操作系统版本、设备配置、设备标识、设备网络信息、设备环境等软硬件特征信息），用于向您提供更契合您需求的服务和内容、了解产品适配性、识别账号异常状态。
    </div>
    <div class="bigContent">
      （六）
      我们会收集您使用“{{appName}}”软件及相关服务的网络日志信息（您使用“{{appName}}”软件及相关服务的日期、时间等），用于识别账号异常状态及满足相关法律法规的要求。
    </div>
    <div class="bigContent">
      （七）
      我们可能使用或整合您的用户信息、设备信息及有关网络日志，为提高您使用我们提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或“{{appName}}”相关协议规则的情况，并用于综合判断您账户风险、进行账户验证、检测及防范安全事件，并依法采取必要的记录、分析、处置措施。
    </div>
    <div class="bigContent">
      （八）
      我们可能会在您与我们联系时保存您的通信/通话记录和内容或您留下的联系方式等信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。
    </div>
    <div class="bigContent">（九） 征得授权同意的例外</div>
    <div class="bigContent">
      根据相关法律法规规定，以下情形中收集您的个人信息无需征得您的授权同意：
    </div>
    <div class="bigContent">1、与国家安全、国防安全有关的；</div>
    <div class="bigContent">2、与公共安全、公共卫生、重大公共利益有关的；</div>
    <div class="bigContent">3、与犯罪侦查、起诉、审判和判决执行等有关的；</div>
    <div class="bigContent">
      4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
    </div>
    <div class="bigContent">5、所收集的个人信息是您自行向社会公众公开的；</div>
    <div class="bigContent">
      6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
    </div>
    <div class="bigContent">7、根据您的要求签订合同所必需的；</div>
    <div class="bigContent">8、法律法规规定的其他情形。</div>
    <div class="bigContent">
      如我们停止运营“{{appName}}”软件及相关服务，我们将立即停止收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并按相关法律法规的规定对所持有的个人信息进行删除或匿名化处理。
    </div>

    <div class="bigTitle">二、我们可能需要获取的您的设备权限</div>
    <div class="bigContent">
      （一）
      我们需要获取访问您的手机相册的授权，用于为您提供您使用您手机相册里含有您或您已经获得授权的第三人头像的照片利用我们的后期技术对平台上的照片或视频进行修改或编辑的服务，如您不使用我们提供的上述服务，则我们不会访问您的手机相册，您也可以随时取消对我们访问您的手机相册的授权。
    </div>
    <div class="bigContent">
      （二）
      我们需要获取访问您的手机相机的授权，用于为您提供您自主自愿拍摄您的头像照片和视频，并利用我们的后期技术对平台上的照片或视频进行修改或编辑的服务，如您不使用我们提供的上述服务，则我们不会访问您的手机相机，您也可以随时取消对我们访问您的手机相机的授权。
    </div>
    <div class="bigContent">
      （三）
      我们需要获取您的手机通知权限，用于我们向您推送相关服务通知，如您不想收到上述通知，则您可以随时关闭我们使用您手机通知的权限。
    </div>
    <div class="bigContent">
      （四）
      我们需要获取您的手机录音权限，用于我们向您提供语音对话服务，如您不使用我们提供的上述服务，则我们不会访问您的手机录音，您可以随时关闭我们使用您手机录音的权限。
    </div>
    <div class="bigContent">
      （五）
      我们需要获取您的手机定位权限，用于我们向您提供基于您当前位置的推荐服务，如您不使用我们提供的上述服务，则我们不会访问您的手机定位，您可以随时关闭我们使用您手机定位的权限。
    </div>

    <div class="bigTitle">三、我们如何共享、披露您的个人信息</div>
    <div class="bigContent">（一）共享</div>
    <div class="bigContent">
      我们不会向任何第三方共享您的个人信息，但您自主自愿选择共享或另行授权我们共享给第三方时，我们会将您同意或选择共享的个人信息共享给您指定的第三方。
    </div>
    <div class="bigContent">（二）披露</div>
    <div class="bigContent">
      我们不会向任何第三方披露您的个人信息，但以下情形除外：
    </div>

    <div class="bigContent">
      1、您自主自愿选择披露或另行授权我们披露给第三方时，我们会将您同意或选择披露的个人信息披露给您指定的第三方。
    </div>
    <div class="bigContent">
      2、我们依据相关法律法规基于下述情形披露您的个人信息：
    </div>

    <div class="bigContent">1）、与国家安全、国防安全有关的；</div>
    <div class="bigContent">
      2）、与公共安全、公共卫生、重大公共利益有关的；
    </div>
    <div class="bigContent">
      3）、与犯罪侦查、起诉、审判和判决执行等有关的；
    </div>
    <div class="bigContent">
      4）、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的
    </div>
    <div class="bigContent">
      5）、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
    </div>
    <div class="bigContent">6）、其他法律法规规定或司机机关要求的情形。</div>
    <div class="bigContent">
      根据《中华人民共和国网络安全法》等相关法律法规的规定，共享、披露经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、及披露行为，对此类数据的保存及处理将不再另行向您通知并征得您的同意。
    </div>

    <div class="bigTitle">四、我们如何保护您的个人信息</div>
    <div class="bigContent">
      （一）我们努力为用户的个人信息提供安全保障，以防止用户个人信息的非法使用、未经授权的访问和披露、泄漏、损坏和丢失。我们将在合理的安全水平内使用各种安全保护措施以保障信息的安全。例如，我们会使用加密技术（例如，SSL）、匿名化处理、加密存储等手段来保护你的个人信息；建立受信赖的保护机制防止个人信息遭到恶意攻击；建立访问控制机制，遵守最小权限原则，确保只有授权人员才可访问个人信息；开展安全和个人信息保护培训课程，加强员工对于保护个人信息保护的认识。
    </div>
    <div class="bigContent">
      （二）我们有行业先进的以数据为核心，围绕数据生命周期进行的数据安全管理体系，从管理机构、组织建设、制度体系、产品技术等方面多维度提升整个系统的安全性。我们建立专门的管理制度、流程和组织以保障个人信息的安全。例如，我们严格限制访问信息的人员范围，要求他们遵守保密义务，并进行审计。若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，并以推送通知、公告等形式告知您。
    </div>
    <div class="bigContent">
      （三）我们会采取合理可行的措施，尽力避免收集无关的个人信息。
    </div>
    <div class="bigContent">
      如您发现自己的个人信息尤其是您的账户或密码发生泄露，请您立即联络我们，以便我们根据您的申请采取相应措施
    </div>
    <div class="bigContent">
      请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息，请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。
    </div>
    <div class="bigContent">
      同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
    </div>

    <div class="bigTitle">五、您如何管理您的个人信息</div>
    <div class="bigContent">您可以通过以下方式访问及管理您的个人信息：</div>
    <div class="bigContent">（一）访问您的个人信息</div>
    <div class="bigContent">
      您有权访问您的个人信息，法律法规规定的例外情况除外。您可以通过以下方式自行访问您的个人信息：
    </div>
    <div class="bigContent">
      账户信息—如果您希望访问或编辑您的账户中的个人资料信息，如头像和昵称，您可以通过通过点击自己的头像-设置来执行此类操作。
    </div>
    <div class="bigContent">
      如果您无法通过上述路径访问该等个人信息，您可以随时通过“{{appName}}”客服与我们取得联系。我们将在15天内回复您的访问请求。
    </div>
    <div class="bigContent">
      对于您在使用我们的产品或服务过程中产生的其他个人信息，我们将根据本条“（五）响应您的上述请求”中的相关安排向您提供。
    </div>
    <div class="bigContent">（二）更正或补充您的个人信息</div>
    <div class="bigContent">
      当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正或补充。您可以通过本条“（一）访问您的个人信息”中列明的方式提出更正或补充申请。
    </div>
    <div class="bigContent">（三）删除您的个人信息</div>
    <div class="bigContent">
      您可以通过本条“（一）访问您的个人信息”中列明的方式删除您的部分个人信息。
    </div>
    <div class="bigContent">
      在以下情形中，您可以向我们提出删除个人信息的请求：
    </div>
    <div class="bigContent">1、如果我们处理个人信息的行为违反法律法规；</div>
    <div class="bigContent">
      2、如果我们收集、使用您的个人信息，却未征得您的明确同意；
    </div>
    <div class="bigContent">
      3、如果我们处理个人信息的行为严重违反了与您的约定；
    </div>
    <div class="bigContent">
      4、如果您不再使用我们的产品或服务，或您主动注销了账号；
    </div>
    <div class="bigContent">5、如果我们永久不再为您提供产品或服务。</div>
    <div class="bigContent">
      若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，要求其及时删除，除非法律法规另有规定，或这些主体获得您的独立授权。
    </div>
    <div class="bigContent">
      当您从我们的服务中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。
    </div>
    <div class="bigContent">（四）改变您授权同意的范围</div>
    <div class="bigContent">
      每个业务功能需要一些基本的个人信息才能得以完成（见本隐私政策“第一条”）。除此之外，对于额外个人信息的收集和使用，您可以在设置或与“{{appName}}”客服联系给予或收回您的授权同意。
    </div>
    <div class="bigContent">
      当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。
    </div>
    <div class="bigContent">（五）响应您的上述请求</div>
    <div class="bigContent">
      为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
    </div>
    <div class="bigContent">
      我们将在15天内做出答复。如您不满意，还可以通过“{{appName}}”客服发起投诉。
    </div>
    <div class="bigContent">
      对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。
    </div>
    <div class="bigContent">
      在以下情形中，按照法律法规要求，我们将无法同意您的请求：
    </div>
    <div class="bigContent">1、与国家安全、国防安全有关的；</div>
    <div class="bigContent">2、与公共安全、公共卫生、重大公共利益有关的；</div>
    <div class="bigContent">3、与犯罪侦查、起诉、审判和执行判决等有关的；</div>
    <div class="bigContent">
      4、有充分证据表明个人信息主体存在主观恶意或滥用权利的；
    </div>
    <div class="bigContent">
      5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；
    </div>
    <div class="bigContent">6、涉及商业秘密的。</div>

    <div class="bigTitle">六、我们如何处理未成年人的个人信息</div>
    <div class="bigContent">
      1、我们特别重视未成年人的个人信息的保护。如果您为14周岁以下的未成年人，在使用我们的产品和/或服务前，请您务必在监护人的陪同下阅读本政策，并确保已征得您的监护人明确同意后，再使用我们的服务并向我们提供您的个人信息。
    </div>
    <div class="bigContent">
      2、对于经监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。
    </div>
    <div class="bigContent">
      3、如果您的监护人不同意您按照本政策使用我们的服务或向我们提供个人信息，请您立即终止使用我们的服务并及时通知我们。
    </div>
    <div class="bigContent">
      4、如果您对您所监护的未成年人使用我们的产品和/或服务或其向我们提供的用户信息有任何疑问时，请您及时与我们联系。
    </div>

    <div class="bigTitle">七、我们存储您的个人信息的位置和方式</div>
    <div class="bigContent">
      我们按本政策收集的您的个人信息，存储在中国境内。
    </div>
    <div class="bigContent">
      我们按本政策收集的您的个人信息，以加密的方式存储。
    </div>
    <div class="bigContent">
      我们按本政策收集的您的个人信息，将在您删除或注销后立即删除或按相关法律法规的规定进行匿名化处理，但按相关法律法规或司法机关的要求需要延长保存时间的除外。在延长保存期间内，我们会以加密形式安全地存储您的个人信息并进行隔离，直至按相关法律法规或司法机关的要求可以删除或匿名化处理。
    </div>

    <div class="bigTitle">八、本隐私政策如何更新</div>
    <div class="bigContent">
      我们的隐私政策可能变更。未经您明确同意，我们不会限制您按照本隐私政策所应享有的权利。对于重大变更，我们会提供显著的通知（包括我们会通过“{{appName}}”公示的方式进行通知甚至向您提供弹窗提示）。
    </div>
    <div class="bigContent">本政策所指的重大变更包括但不限于：</div>
    <div class="bigContent">
      1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
    </div>
    <div class="bigContent">
      2、我们在控制权等方面发生重大变化。如并购重组等引起的所有者变更等；
    </div>
    <div class="bigContent">
      3、个人信息共享、转让或公开披露的主要对象发生变化；
    </div>
    <div class="bigContent">
      4、您参与个人信息处理方面的权利及其行使方式发生重大变化；
    </div>
    <div class="bigContent">
      5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
    </div>
    <div class="bigContent">6、个人信息安全影响评估报告表明存在高风险时。</div>

    <div class="bigTitle">九、如何联系我们</div>
    <div class="bigContent">
      您可以通过“{{appName}}”客服与我们联系，我们将在15天内回复您的请求。如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案。
    </div>
    <div class="bigTitle">十、用户注销说明</div>
    <div class="bigContent">
      在您注销您的账户之前，请充分阅读、理解并同意下列事项：
    </div>
    <div class="bigContent">
      如需注销账户，请通过发送账户信息至邮件yanghejingxiang@126.com进行注销，我们会在收到您的邮件后的5个工作日内为您处理注销账户的事项。
    </div>
    <div class="bigContent">
      在此善意地提醒您，您注销账户的行为，将导致我们终止对您提供本服务，也会给您的售后维权带来诸多不便。注销成功后，我们将删除您的个人信息，使其保持不可被检索、访问的状态，或对其进行匿名化处理。
    </div>
    <div class="bigContent">
      1.如果您仍执意注销账户，您的账户需同时满足以下条件：
    </div>
    <div class="bigContent">
      （1）账户内无未完成的订单、已提供服务但未支付的订单/服务；
    </div>
    <div class="bigContent">
      （2）账户无任何纠纷，包括投诉举报或被投诉举报；
    </div>
    <div class="bigContent">
      （3）账户为正常使用中的账户且无任何账户被限制的记录；
    </div>
    <div class="bigContent">
      2.账户一旦被注销将不可恢复，请您在操作之前自行备份账户相关的所有信息和数据。注销账户，您将无法再使用本账户，也将无法找回您账户中及与账户相关的任何内容或信息（即使您使用相同的手机号码再次注册并使用），包括但不限于：
    </div>
    <div class="bigContent">（1）您将无法登录、使用账户；</div>
    <div class="bigContent">
      （2）账户的个人资料和历史信息（包括但不限于用户名、昵称、头像、记录等）都将无法找回；
    </div>
    <div class="bigContent">
      3.注销账户并不代表账户注销前的账户行为和相关责任得到豁免或减轻。
    </div>

    <!-- <div class="bigContent"></div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      // appName: "仰和安心",
      // companyName: "深圳市仰和镜象技术有限公司",
      appName: "",
      companyName: "",
    };
  },
  created () {
    this.appName = this.$route.query.aName
    this.companyName = this.$route.query.cName
  }
};
</script>
<style lang="scss" scoped>
.home{
  box-sizing: border-box;
  padding: .3rem;
  background-color: #f7f7f7; 
  img{
    width: 1.28rem;
    height: .36rem;
  }
  .bigTitle{
    font-size: .28rem;
    color: #333;
    font-weight: bold;
    padding: .3rem 0 0;
  }
  .bigContent{
    font-size: .28rem;
      color: #4d4d4d;
      padding: .2rem 0 0;
      line-height: .4rem;
  }
}
</style>

